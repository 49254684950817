.project-row {
    justify-content: space-between;
    max-width: 1185px;
    margin: 0 auto;
}

.project-card {
    height: 100%;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}

.project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.project-card-img {
    object-fit: cover;
    height: 200px;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .project-row {
        justify-content: space-around;
        max-width: 95vw;
    }
}

@media (max-width: 992px) {
    .project-row {
        padding: 0 5vw;
    }
}
