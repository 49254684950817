#footer {
    width: 100%;
    padding: 0 2.085vw; /* padding: 0 40px; */
    position: static;
    background-color: #f0f0f0;
}
.footer-navbar,
.footer-right-navbar-socials > * {
    padding: 0 !important;
}

.footer-container {
    max-width: 1920px;
    margin: 0 auto;
    padding: min(3vw, 70px) 0 !important; /* padding: 80px 0px; */
}
#footer .container-fluid {
    padding: 0 !important;
    margin-right: 2.6vw !important; /*  margin-right: 50px */
}

#footer .navbar-brand {
    align-self: flex-start;
    color: #002021;
    text-transform: uppercase;
}
.footer-right-navbar {
    flex-direction: column;
    align-items: end;
}
.footer-right-navbar-socials {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: min(2.2vw, 40px);
}
.footer-right-navbar-socials img {
    height: 100%;
    max-width: 70px;
    object-fit: scale-down;
}
.footer-right-navbar-top .navbar-text {
    padding-bottom: 2rem !important;
    color: #002021;
    line-height: 1rem; /* font-size: 24px; */
    text-transform: lowercase;
}
.footer-copyright {
    max-width: 1920px;
    margin: 0 auto;
    border-top: 1px solid black;
}
#footer .py-4 {
    padding: min(2.5vw, 25px) 0 !important; /* padding: 40px 0px; */
}
.footer-copyright p {
    color: #6d6d6d;
    font-size: 1rem;
    margin: 0 !important;
}

@media (max-width: 1200px) {
    #footer .navbar-brand .h1 {
        font-size: 1.6rem;
        margin-bottom: 0 !important;
    }
    .footer-right-navbar-top .navbar-text {
        padding: 0 !important;
    }
    .footer-right-navbar-socials {
        height: auto;
        padding-top: 1vw;
    }
    .footer-right-navbar-socials img {
        height: 6vw;
        width: auto;
    }
}
@media (max-width: 576px) {
    #footer .container-fluid {
        flex-direction: column;
        flex-wrap: wrap;
        align-content: flex-start;
    }
    #footer .navbar-brand .h1 {
        font-size: 1rem !important;
    }
    .footer-right-navbar-top {
        display: none;
    }
    .footer-copyright p {
        font-size: 0.6rem;
    }
}
