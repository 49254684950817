#homepage {
    background-color: #fff;
    /* padding: 180px 58px 265px !important; padding-top: 294px, padding-bottom: 265px; navBar height = 114px */
    padding: 18vh 3vw 26vh !important;
}
#homepage .container {
    max-width: 1920px;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
#homepage .row {
    margin: auto 0 !important;
    justify-content: space-between;
}
.cta-block {
    max-width: 860px;
    margin: auto 0;
    padding: 0;
}
.cta-block .h1 {
    font-size: min(calc(4.4vw), 72px);
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}
.cta-description {
    font-size: min(calc(2.9vw), 55px);
    display: grid;
    align-items: center;
    justify-content: space-between;
}
.cta-btn {
    max-width: 180px;
    padding: 6px 24px !important;
    white-space: nowrap;
    font-size: min(1.3vw, 25px);
    transition: opacity 0.3s ease;
}
#homepage #btn-show-on-mobile {
    display: none;
}
.carousel-block {
    max-width: 860px;
    padding: 16px 0 !important;
}
.carousel {
    max-width: 748px;
    margin: auto;
}
.image-contaner-div {
    width: 100% !important;
    height: auto !important;
}
.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.image-contaner-div img {
    max-width: 748px !important;
    height: 412px;
    width: 100% !important;
    height: auto !important;
    border-radius: 20px !important;
}
.carousel-indicators {
    margin-bottom: -70px !important;
    padding: 16px;
}
.carousel-indicators [data-bs-target] {
    width: 8px !important;
    height: 8px !important;
    border-radius: 100%;
    background-color: #c4c4c4 !important;
}
.carousel-indicators .active {
    background-color: #000 !important;
}

/* Media queries for responsiveness */
@media (max-width: 992px) {
    #homepage {
        padding: 8vh 4vw 26vh !important;
    }
    .cta-block {
        max-width: 748px;
        margin: 0 auto 2vw;
        padding: 0;
    }
    .cta-description {
        display: block;
        font-size: 3.4vw;
    }
    .cta-btn {
        max-width: 140px;
        font-size: min(2.2vw, 20px);
    }
    #homepage #btn-dont-show-on-mobile {
        display: none;
    }
    #homepage #btn-show-on-mobile {
        display: flex;
        text-decoration: none;
        justify-content: space-evenly;
        margin: 2rem auto 0 !important;
    }
    .carousel-indicators {
        margin-bottom: -50px !important;
    }
}
