.researchers-card-container img {
    margin: 0 auto;
    border: 3px solid #00447c;
}
.researchers-col {
    padding-bottom: 0 !important;
    display: flex;
    justify-content: center;
}

/* Medium devices: 576 to 992 */
@media (max-width: 992px) {
    #researchers-card {
        padding: 1rem 0 !important;
    }
    .researchers-card-container img {
        width: 110px !important;
        height: 110px !important;
        border: 2px solid #00447c;
    }
}

/* Small devices */
@media (max-width: 576px) {
    .researchers-card-container img {
        width: 20vw !important;
        height: 20vw !important;
    }
    #researchers-card .card-body > * {
        margin: 0 !important;
    }
    #researchers-card .name {
        font-size: 1.2rem;
    }
    #researchers-card .py-2 {
        padding: 0;
    }
    .researchers-card-container {
        padding-bottom: 0 !important;
    }
    #researchers-card .h6 {
        font-size: 0.9rem;
        text-overflow: unset;
        margin: 0 !important;
    }
}
