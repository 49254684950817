#mission {
    background-color: #f0f0f0;
    padding: 3rem 0;
}

.mission-heading {
    margin-bottom: 1rem;
}

.mission-subheading {
    margin-bottom: 2rem;
}

.mission-item {
    border: none !important;
    border-radius: 20px !important;
    background-color: #ffffff;
    min-height: 145px;
    padding: 1rem;
}

.mission-icon {
    width: 50px;
    height: 50px;
}

.app-store-badge {
    max-width: 250px;
    width: 150px;
    height: 72px;
    margin-right: 10px;
}

.google-play-badge {
    max-width: 250px;
    width: 190px;
    height: auto; /* can't make it the same height as apple's (it'll get smaller), so we lift the height of Apple's to reach Google's height */
    margin-left: 10px;
}

.icon-btn {
    border: none;
    padding: 0; 
    display: flex; 
    align-items: center; 
}

@media (min-width: 1920px) {
    .mission-container {
        max-width: 1680px;
    }
    .mission-heading-container {
        padding-left: 120px;
        padding-right: 120px;
    }
}