#policies {
    background-color: #fff;
    padding: 2rem;
}

.policies-menu {
    padding: 1rem;
    border-radius: 8px;
}

.policies-menu-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #00447c;
    margin-bottom: 1rem;
}

.policies-menu-list {
    list-style: none;
    padding-left: 0;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.button-17 {
    align-items: center;
    background-color: #d11242;
    border-radius: 24px;
    box-shadow:
        rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
        rgba(0, 0, 0, 0.14) 0 6px 10px 0,
        rgba(0, 0, 0, 0.12) 0 1px 18px 0;
    color: white;
    display: inline-flex;
    fill: currentcolor;
    font-family: 'Google Sans', Roboto, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 48px;
    padding: 2px 24px;
    transition:
        box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
        opacity 15ms linear 30ms,
        transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    user-select: none;
    -webkit-user-select: none;
    will-change: transform, opacity;
    z-index: 0;
}

.button-17:hover {
    background: #f6f9fe;
    color: black;
}

.button-17:active {
    box-shadow:
        0 4px 4px 0 rgb(60 64 67 / 30%),
        0 8px 12px 6px rgb(60 64 67 / 15%);
    outline: none;
}

.button-17:focus {
    outline: none;
    border: 2px solid #00447c;
}

.button-17:not(:disabled) {
    box-shadow:
        rgba(60, 64, 67, 0.3) 0 1px 3px 0,
        rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.button-17:not(:disabled):hover {
    box-shadow:
        rgba(60, 64, 67, 0.3) 0 2px 3px 0,
        rgba(60, 64, 67, 0.15) 0 6px 10px 4px;
}

.button-17:not(:disabled):focus {
    box-shadow:
        rgba(60, 64, 67, 0.3) 0 1px 3px 0,
        rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.button-17:not(:disabled):active {
    box-shadow:
        rgba(60, 64, 67, 0.3) 0 4px 4px 0,
        rgba(60, 64, 67, 0.15) 0 8px 12px 6px;
}

.button-17:disabled {
    box-shadow:
        rgba(60, 64, 67, 0.3) 0 1px 3px 0,
        rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.policies-content {
    padding: 2rem;
    border-left: 1px solid #ddd;
}

.policies-content h3 {
    color: #00447c;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.policies-content p {
    color: #333;
    font-size: 1rem;
    line-height: 1.6;
}